@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    body {
        @apply text-base text-base md:text-xl
    }
    h1 {
        @apply font-mono font-normal
    }
    section {
        @apply py-10 sm:py-64
    }

}


html {
    scroll-behavior: smooth;
}

.home:after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 50vh;
    z-index: 30;
    pointer-events: none;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0d0d",endColorstr="#0d0d0d",GradientType=1);
}

.hero-title span,.hero-title2 span{
    display: block;

    transition: all 1s cubic-bezier(0.25, 0.1, 0.1, 1);
    transform: translateY(100%);
}
.hero-title.is-inview span,.hero-title2.is-inview span {
    transform: translateY(0);
}
.hero-title2 span {
    transition-delay: 0.3s;
}
.menu a {
    @apply text-base
}

.menu a {
    position: relative;
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    padding: 10px 20px;
    transition: all ease .3s;
}
.menu a:hover,.menu a.active {

    color:white
}
.menu a:before{
    content: '';
    z-index: -1;
    position: absolute;
    background: rgb(220,38,38);
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .75s cubic-bezier(0.165, 0.84, 0.44, 1);

}

.menu a:hover:before, .menu a.active:before {
    transform-origin: left;
    transform: scaleX(100%);
}
.hambuger-menu.open > div span:first-child{
    @apply rotate-[45deg] top-[8px]
}
.hambuger-menu.open > div span:nth-child(2){
    @apply opacity-0
}
.hambuger-menu.open > div span:last-child{
    @apply -rotate-[45deg] -top-[8px]
}

@keyframes open-menu {
    from {transform: scaleX(100%);}
    to {transform: scaleX(0);}
}
@media (min-width: 768px){

    header:before {
        position: absolute;
        content: '';
        top: -1px;
        left: 0;
        width: 100%;
        height: 20vh;
        z-index: -1;
        pointer-events: none;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0d0d",endColorstr="#0d0d0d",GradientType=1);
    }

    /* Regular CSS rules here*/
    .animated-text:after {
        content: '';
        position: absolute;
        background: rgb(220,38,38);
        left: 0;
        top: 0;
        width: 100%;
        height:100%;
        transform-origin: right;
        transform: scaleX(100%);
        transition: transform .75s cubic-bezier(0.165, 0.84, 0.44, 1) .5s;
    }
    .animated-text.is-inview:after {
        transform: scaleX(0);
    }

    .animated-img:after {
        content: '';
        position: absolute;
        background: rgb(220,38,38);
        left: 0;
        top: 0;
        width: 100%;
        height:100%;
        transform-origin: right;
        transform: scaleX(100%);
        transition: transform 1s cubic-bezier(.25,.1,.1,1);
        z-index: 50;
    }
    .animated-img.animate-left:after {
        transform-origin: left;
    }
    .animated-img.is-inview:after {
        transform: scaleX(0);
    }

}


.fair .overlay {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    z-index: 1;

}

.fair:hover .overlay {
    transform-origin: left;
    transform: scaleX(100%);
}

input[type=text],input[type=email],input[type=phone],textarea {
    @apply block border-2 border-white px-8 py-5 mb-5 w-full transition-all hover:border-red-600 focus:border-red-600 hover:outline-0 focus:outline-0
}

html.has-scroll-smooth {
    overflow: hidden; }

html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.has-scroll-smooth body {
    overflow: hidden; }

.has-scroll-smooth [data-scroll-container] {
    min-height: 100vh; }

[data-scroll-direction="horizontal"] [data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap; }

[data-scroll-direction="horizontal"] [data-scroll-section] {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 100%; }

.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0; }
.c-scrollbar:hover {
    transform: scaleX(1.45); }
.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; }
[data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); }
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
    transform: scaleY(1.3); }

.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab; }
.has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0; }
